// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./img/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*GLOBAL*/
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
}

/*LANGUAGE*/
.languageSwitcher {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 10px;
    right: 0px;
    bottom: 0px;
}

.languageSwitcher button {
    background: #f0f8ff00;
    border: 0px;
}

.languageSwitcher img {
    width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,SAAS;AACT;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,yDAA2C;IAC3C,sBAAsB;AAC1B;;AAEA,WAAW;AACX;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":["/*GLOBAL*/\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nbody {\n    background-image: url(./img/background.png);\n    background-size: cover;\n}\n\n/*LANGUAGE*/\n.languageSwitcher {\n    position: fixed;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    right: 0px;\n    bottom: 0px;\n}\n\n.languageSwitcher button {\n    background: #f0f8ff00;\n    border: 0px;\n}\n\n.languageSwitcher img {\n    width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
